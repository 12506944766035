import {  createValetBooking, addPaymentIdToBooking } from "../../../../utils/apis/bookings_repository";
import { Text } from "../../Text"
import { useEffect, useState } from "react";
import { Button } from "../../CustomButton";
import { SheetClose } from "../../Sheets";
import { createValetUser, UserModel } from "../../../../utils/apis/user_repository";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllUsers } from "../../../../utils/apis/user_repository";
import { Spinner } from "../../Spinner";
import { getUserFromLocalStorage} from "../../../../utils/helper";
import { Divider } from "@mui/material";
import {DateTime } from "luxon";
import {showToast  } from "../../../../utils/toast";
import { fetchDirectDebitDetailsByEmail, fetchPaymentDetailsByEmail, handleExistingPayments } from "../../../../utils/apis/payment_repository";
import DatePicker from 'react-datepicker';
import { useNavigate } from "react-router-dom";
interface ValetDetailsBodyProps {
    setDialogState: (state: boolean) => void;
}
export const getTimeNowInAussie = (): Date => {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'Australia/Sydney',
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const parts = formatter.formatToParts(date);

    const aussieDateParts = parts.reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {} as Record<string, string>);

    const aussieTime = new Date(
        `${aussieDateParts.year}-${aussieDateParts.month}-${aussieDateParts.day}T${aussieDateParts.hour}:${aussieDateParts.minute}:${aussieDateParts.second}`
    );

    return aussieTime;
};
const ValetDetailsBody = ({ setDialogState }: ValetDetailsBodyProps) => {

    const currentUser = getUserFromLocalStorage();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [retailUserName, setRetailUserName] = useState("");
    const [retailUserEmail, setRetailUserEmail] = useState("");
    const [retailUserPhone, setRetailUserPhone] = useState("");
    const [retailUserPlate, setRetailUserPlate] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [inputsDisabled, setInputsDisabled] = useState(false);

    const [savedCardPaymentMethods, setCardSavedPaymentMethods] = useState<any[]>([]); // State for saved payment methods
    const [savedPaymentMethods, setSavedPaymentMethods] = useState<any[]>([]); // State for saved payment methods
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null); // To store user selection
    const [showNewCardForm, setShowNewCardForm] = useState(false);
    const [bookingType, setBookingType] = useState("Single");
    const [creditCustomerId, setCreditCustomerId] = useState("");
    const [debitCustomerId, setDebitCustomerId] = useState("");
    const [bookingId , setBookingId] = useState("");


    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [couponCode, setCouponCode] = useState("");
    const [couponName, setCouponName] = useState("");
    const [couponPercent, setCouponPercent] = useState(0);
    const [couponValid, setCouponValid] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [couponError, setCouponError] = useState("");
    const [amount, setAmount] = useState(80);

    const navigate = useNavigate();

    const [valetUser, setValetUser] = useState<UserModel>({
        cardholderId: '',
        gallagherId: '',
        email: '',
        name: '',
        vehicles: [],
        role: '',
        tenant: '',
        permanentParking: false,
        userId: '',
        bookingLimit: 0,
        futureBookingPeriod: 0,
        lastMinuteBookingPeriod: 0,
    });

    const handleCouponValidation = async () => {
        setCouponError("");  // Reset coupon error message
        if (!couponCode) {
            setCouponError("Please enter a coupon code.");
            return;
        }
    const stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

    try {
        const response = await fetch('https://api.stripe.com/v1/coupons', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${stripeSecretKey}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        const data = await response.json();

        if (response.ok && data.data.length) {
            // Find the coupon that matches the input coupon code
            const coupon = data.data.find((c: { id: string; }) => c.id === couponCode.toUpperCase());

            if (coupon && coupon.valid) {
                setCouponValid(true);
                setCouponName(coupon.name);
                setCouponPercent(coupon.percent_off);
                let discountAmount = coupon.percent_off ?
                    (amount * coupon.percent_off / 100) :
                    (coupon.amount_off / 100);
                setDiscountAmount(discountAmount);
                setAmount(prevAmount => Math.max(0, prevAmount - discountAmount));
            } else {
                setCouponValid(false);
                setDiscountAmount(0);
                setCouponError("Invalid coupon code.");
            }
        } else {
            throw new Error("Failed to retrieve coupons or no coupons available");
        }
    } catch (error) {
        console.error("Failed to validate coupon:", error);
        setCouponError("No Coupons Available at the moment.");
    }
};

    const [startDate, setStartDate] = useState<Date>(
        DateTime.now().toJSDate()
    );

    const handleClickSingle = () => {
        setBookingType("Single");
        setStartDate(DateTime.now().toJSDate());
    }
    const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        const selectedMethod = event.target.value;
        setPaymentMethod(selectedMethod);
        console.log("Payment method changed to:", selectedMethod);
    };


      const [enableBooking, setEnableBooking] = useState(true); // State to enable/disable the booking button

// Effect hook to handle payment method type changes and check card availability
useEffect(() => {
    if (paymentMethod === "card" && savedCardPaymentMethods.length === 0) {
        setEnableBooking(false); // Disable booking button if no card details are available
    } else if (paymentMethod === "direct-debit" && savedPaymentMethods.length === 0) {
        setEnableBooking(false); // Disable booking button if no card details are available
    } else {
        setEnableBooking(true); // Enable booking button otherwise
    }

    if (savedCardPaymentMethods.length === 0 && savedPaymentMethods.length === 0) {
        setInputsDisabled(true);
    } else {
        setInputsDisabled(false);
    }
}, [paymentMethod, savedCardPaymentMethods, savedPaymentMethods]);
    useEffect(() => {
        if (currentUser.email) {
            (async () => {
                try {
                    const paymentDetails = await fetchPaymentDetailsByEmail(currentUser.email);
                    if (paymentDetails && paymentDetails.paymentMethods) {
                        setCardSavedPaymentMethods(paymentDetails.paymentMethods);
                        console.log("card details=== ", paymentDetails.id);
                        setCreditCustomerId(paymentDetails.id)
                        // console.log("oooooooooooo " , paymentDetails.paymentMethods[0].id)
                    }
                } catch (error) {
                    console.error("Failed to fetch and set payment details:", error);
                }
            })();
        }
    }, [currentUser.email]);

    useEffect(() => {
        if (currentUser.email) {
            (async () => {
                try {
                    const paymentDetails = await fetchDirectDebitDetailsByEmail(currentUser.email);
                    if (paymentDetails && paymentDetails.paymentMethods) {
                        setSavedPaymentMethods(paymentDetails.paymentMethods);
                    }
                } catch (error) {
                    console.error("Failed to fetch and set payment details:", error);
                }
            })();
        }
    }, [currentUser.email]);

    const handleDateChange = async (date: Date): Promise<void> => {
        setSelectedDate(date);
        // setLoading(true);
        // const isAvailable = await checkDateAvailability(date);
        // setIsDateAvailable(isAvailable);
        // setLoading(false);
    };
const handleSelectedPaymentMethod = (data: any) => {
    setSelectedPaymentMethod(data);
}
const queryClient = useQueryClient();
const getPublicIp = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip; // Returns the user's public IP
    } catch (error) {
        console.error('Failed to fetch IP address:', error);
        return '127.0.0.1'; // Default to localhost IP as a fallback
    }
};

const handleExistingPayment = async () => {
    setIsLoading(true);
    try {
        let bookingStartTime = new Date(selectedDate!);
        bookingStartTime.setHours(0, 0, 0, 0);

        const aussieTimeNow = getTimeNowInAussie();
        const isToday = aussieTimeNow.toDateString() === bookingStartTime.toDateString();
        let bookingTime = '';
        if (isToday && aussieTimeNow.getHours() >= 12) {
            const hours = aussieTimeNow.getHours().toString().padStart(2, '0');
            const minutes = aussieTimeNow.getMinutes().toString().padStart(2, '0');
            const seconds = aussieTimeNow.getSeconds().toString().padStart(2, '0');
            bookingTime = `${hours}:${minutes}:${seconds}`;
        }

        const valetUserId = await createValetUser(valetUser);

        const booking = {
            userId: valetUserId,
            name: retailUserName,
            plateNumber: retailUserPlate,
            startTime: selectedDate!.toString(),
            endTime: selectedDate!.toString(),
            email: retailUserEmail,
            bookingStartTime: bookingTime,
            phone: retailUserPhone,
            venue: currentUser.restaurantName,
        };

        try {
            const { bookingId, isApproved } = await createValetBooking(booking);
            if (!bookingId) {
                console.error("Booking creation failed");
                return;
            }

            console.log("Booking created successfully");
            showToast("Booking created successfully", {type: "success"})
            setBookingId(bookingId);
            const bookingIdLast4 = bookingId.slice(-4);
            const amountInCents = Math.round(amount * 100);

            if(couponPercent === 100.0){
                  const success = await addPaymentIdToBooking(bookingId, "freeCoupon", couponName, couponPercent, currentUser.restaurantName, retailUserPlate);
                              if (success) {
                                  console.log("Successfully added Stripe ID to booking");
                                  window.location.reload();
                              } else {
                                  console.log("Failed adding Stripe ID to booking");
                              }


            }else{
                if (paymentMethod === "direct-debit") {
                    // Direct Debit Payment
                    const userIp = await getPublicIp();
                    const response = await fetch('https://api.stripe.com/v1/payment_intents', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                        },
                        body: new URLSearchParams({
                            'mandate_data[customer_acceptance][type]': 'online',
                            'mandate_data[customer_acceptance][online][ip_address]': userIp,
                            'mandate_data[customer_acceptance][online][user_agent]': navigator.userAgent,
                            amount: amountInCents.toString(),
                            currency: 'aud',
                            customer: savedPaymentMethods[0].customer,
                            payment_method: savedPaymentMethods[0].id,
                            setup_future_usage: 'off_session',
                            description: `Initial fee for vehicle at ${currentUser.restaurantName} on ${startDate} bookingId ${bookingIdLast4}`,
                            confirm: 'true',
                            'payment_method_types[]': 'au_becs_debit',
                        }),
                    });

                    const paymentResponse = await response.json();
                    if (response.ok && paymentResponse.id) {
                        console.log("Paid successfully with Direct Debit");
                        const success = await addPaymentIdToBooking(bookingId, paymentResponse.id,couponName, couponPercent, debitCustomerId, retailUserPlate);
                        if (success) {
                            console.log("Successfully added Stripe ID to booking");
                        } else {
                            console.error("Failed adding Stripe ID to booking");
                        }
                    } else {
                        console.error("Payment failed:", paymentResponse);
                    }
                } else {
                     const description =  `Initial fee for vehicle at ${currentUser.restaurantName} on ${startDate} bookingId ${bookingIdLast4}`;
                    // Card Payment
                    const paymentId = await  handleExistingPayments(creditCustomerId, amount , description);
                    if(paymentId !== ''){ console.log("paid succesfly")
                      const success = await addPaymentIdToBooking(bookingId, paymentId, couponName, couponPercent, creditCustomerId, retailUserPlate);
                                  if (success) {
                                      console.log("Successfully added Stripe ID to booking");
                                  } else {
                                      console.log("Failed adding Stripe ID to booking");
                                  }
                    }
                }
            }

        } catch (error) {
            console.error("Error creating booking:", error);
        }
        setIsLoading(false);
        queryClient.invalidateQueries({ queryKey: ['bookings'] }).then(() => {
            setDialogState(false);
        });
    } catch (paymentError) {
        console.error("Error processing payment:", paymentError);
        setIsLoading(false);
    }
};

    const handleCreateBooking = async () => {

        if(retailUserName && retailUserEmail && retailUserPhone){
            handleExistingPayment()
        }else{
            showToast("Please fill all fields", {type: "error"})
        }
}

    const userData = getUserFromLocalStorage();
    const isDisabled =
    !selectedDate ||
    !retailUserName.trim() ||
    !retailUserEmail.trim() ||
    !retailUserPhone.trim() ||
    (paymentMethod === '' || !enableBooking);

    const { data: allUsers, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['users'],
        queryFn: () => getAllUsers(
            userData.role === "SuperAdmin" ? undefined : userData.tenant
        ),
    })

    if (isError) {
        return <div className="flex justify-center">Something went wrong</div>
    }
    const getAllowedDays = () => {
        const days = process.env.REACT_APP_ALLOWED_DAYS || "5,6";
        return days.split(',').map(Number);
    };
    const allowedDays = getAllowedDays();
    return (
        <div >
            {dataLoading ? <div className="flex items-center flex-col justify-center">
                <Text as="span">Getting all User Details, Please wait</Text>
                <div className="mt-4">
                    <Spinner />
                </div>
            </div> : <div>
                <div className="flex pt-10 flex-row items-center justify-between">
                    <Text as="h2">Create New Booking</Text>
                </div>
                <div className="pt-4">
                    <Divider className="bg-secondaryText" />
                </div>
                <div className="scrollable-content" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {inputsDisabled ? (
            <div className="text-red-500 mt-3">
                * No card details or debit account details found. Please <a href="/payment/card" className="text-blue-500 underline">add a new payment method</a> to continue.
            </div>
        ):null}
                <Text as="h3">Booking Type</Text>
                <div className="flex flex-row space-x-4 mt-6">
                    <Button onClick={handleClickSingle} variant={bookingType === "Single" ? "default" : "secondary"} className={`w-44 border-primary ${bookingType === "Single" ? "text-white" : "border-primary text-primaryText"}`}>Valet Booking</Button>
                </div>
                <Text className="mt-10" as="h3">Booking Date</Text>
                <div className="mt-6">
                <DatePicker
                            disabled={inputsDisabled}
                            minDate={getTimeNowInAussie()}
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                            filterDate={(date) => {
                                const day = date.getDay();
                                return allowedDays.includes(day);
                              }}
                              placeholderText={getTimeNowInAussie().getDay() === 5 || getTimeNowInAussie().getDay() === 6 ? "Select a Date" : "Date*"}
                        />
                        </div>


<div className="mt-8">
                    <Text as="h3">Retail User Details</Text>
                    <div className="flex flex-col gap-4 mt-6">
                    <input
                                    className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                    type="text"
                                    placeholder="Name*"
                                    value={retailUserName}
                                    onChange={(e) => setRetailUserName(e.target.value)}
                                    disabled={inputsDisabled}
                                    required
                                />
                                <input
                                    className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                    type="text"
                                    placeholder="Email*"
                                    value={retailUserEmail}
                                    onChange={(e) => setRetailUserEmail(e.target.value)}
                                    disabled={inputsDisabled}
                                    required
                                />
                                 <input
                                    className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                    type="text"
                                    placeholder="Phone*"
                                    value={retailUserPhone}
                                    onChange={(e) => setRetailUserPhone(e.target.value)}
                                    disabled={inputsDisabled}
                                    required
                                />
                                 <input
                                    className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                    type="text"
                                    placeholder="Plate"
                                    value={retailUserPlate}
                                    onChange={(e) => setRetailUserPlate(e.target.value.toUpperCase())}
                                    disabled={inputsDisabled}
                                />
                    </div>

                </div>
<div  style={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }} >

  <Text as="h3" className="text-lg font-bold mb-4">
                Select Payment Method
            </Text>
   <div className="FormRow flex mb-2 mt-3">
                <select
                   disabled={inputsDisabled}
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                    className="date-picker mb-4 mr-3 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400" >
                    <option value="" disabled>Select Payment Type</option>
                    <option value="card">Pay with Card</option>
                    <option value="direct-debit">Direct Debit</option>
                </select>

                {/* Saved Payment Methods */}
            { enableBooking ?  <select
                    id="saved-payment-methods"
                    value={selectedPaymentMethod || ''}
                    onChange={(e) => {
                        const selectedValue = e.target.value;
                        handleSelectedPaymentMethod(selectedValue);

                        // Show form for "New" selection
                        if (selectedValue === 'new') {
                            setShowNewCardForm(true);
                        } else {
                            setShowNewCardForm(false);
                        }
                    }}
                    disabled={!paymentMethod}
                    className={`w-1/3 border-input ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground h-12 rounded-md border hover:border-2 focus-visible:outline-blue-500 bg-transparent px-4 py-2 text-md disabled:cursor-not-allowed disabled:opacity-50 border-gray-400 ${
                        !paymentMethod ? 'bg-gray-100 cursor-not-allowed' : ''
                    }`}
                >
                    <option value="" disabled>Select Saved Method</option>
                    {paymentMethod === "card" && savedCardPaymentMethods.map((method) => (
            <option key={method.id} value={method.id}>
                {`${method.card.brand.charAt(0).toUpperCase() + method.card.brand.slice(1)} **** **** **** ${method.card.last4}`}
            </option>
        ))}
        {paymentMethod === "direct-debit" && savedPaymentMethods.map((method) => (
            <option key={method.id} value={method.id}>
                {`Account ***** ${method.au_becs_debit.last4}`}
            </option>
        ))}
                </select>: null
}
            </div>
            {paymentMethod === "card" && savedCardPaymentMethods.length === 0 && (
            <div className="text-red-500">
                * No card details found. Please <a href="/payment/card" className="text-blue-500 underline">add a new card</a> to continue.
            </div>
        )}
        {paymentMethod === "direct-debit" && savedPaymentMethods.length === 0 && (
            <div className="text-red-500">
                * No direct debit account details found. Please <a href="/payment/direct-debit" className="text-blue-500 underline">add a new account</a> to continue.
            </div>
        )}

<div className="FormRow flex mr-3 mt-4 mb-4">
        <input
            type="text"
            placeholder="Enter coupon code"
            value={couponCode}
            onChange={(e) => {
                setCouponCode(e.target.value);
                // Reset validation state if the user alters the coupon code
                if (couponValid || couponError) {
                    setCouponValid(false);
                    setCouponError('');
                }
            }}
            disabled={couponValid}
            className="date-picker mb-4 mr-3 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
        />
        <button
            type="button"
            onClick={handleCouponValidation}
            className={`button-field flex-none w-48 h-12 px-4 text-white font-medium rounded-md ${
                !couponValid && !couponError
                    ? 'bg-primary hover:bg-primary-dark'
                    : couponValid
                    ? 'bg-green-500 hover:bg-green-600'
                    : 'bg-red-500 hover:bg-red-600'
            } disabled:cursor-not-allowed disabled:opacity-50`}
            disabled={!couponCode.trim() || couponValid}
        >
            {couponValid ? 'Coupon Valid' : couponError ? 'Invalid Coupon' : 'Apply Coupon'}
        </button>
    </div>
    {couponError && !couponValid && <p className="text-red-500">{couponError}</p>}
    {couponValid && (
        <>
             <div className="coupon-box ">
            <span><strong>{couponCode}</strong> is applied</span>
            <span className="coupon-desc">{couponName}</span>
        </div>
            <div className="Summary">
                <p>Original Amount: <span className="amount">$80.00</span></p>
                <p>Discounts: <span className="amount">-${discountAmount.toFixed(2)}</span></p>
                <hr className="summary-divider" />
                <p>Total: <span className="amount">${(amount).toFixed(2)}</span></p>
            </div>
        </>
    )}
                <div className="absolute bottom-0 mx-12 right-0 mb-10 flex flex-row justify-end">
                    <SheetClose asChild>
                        <Button variant={"secondary"} className="w-44 border border-primary text-primaryText">Cancel</Button>
                    </SheetClose>
                    <Button variant={"default"} onClick={handleCreateBooking} isLoading={isLoading} disabled={isDisabled} className="w-44 ml-4 text-white">Create Booking</Button>
                </div>



                </div>
                </div>
            </div>}
        </div>
    );
}

export default ValetDetailsBody;